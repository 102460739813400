.signup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border: 1px solid #ccc;
    z-index: 1;
    margin-top: 50px;
  }

  .signup-header {
    margin-bottom: 20px;
  }
  
  .signup-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .signup-input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 250px;
  }
  .signup-button {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .signup-form {
    margin-top: 10px;
    text-align: center;
  }
  